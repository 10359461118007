import topic from '../topic';
import { getNoCatch } from '@api/request';

export const priceConsultAPI = {
  getPriceConsult: topic.priceConsultTopic + '/getPriceConsultList',
  getPriceConsultById: topic.priceConsultTopic + '/getPriceConsultById',
  editPriceConsult: topic.priceConsultTopic + '/editPriceConsult',
  getPcstByInquiryPartId: topic.priceConsultTopic + '/getPcstByInquiryPartId',
  distribute: topic.priceConsultTopic + '/distribute',
  subToBoss: topic.priceConsultTopic + '/subToBoss',
  exportPartList: params => getNoCatch(`${topic.priceConsultTopic}/exportPartList`, params)
};
