<template>
  <div class="vg_wrapper" v-loading="loadFlag">
    <div class="vd_edhea vg_button_group">
      <el-row class="priceConsult_header">
        <el-col>
          <editHeader
            :isShow="isShow"
            :btn="btn"
            :strForm="priceConsultFrom"
            @openEdit="openEdit"
            @closeEdit="closeEdit"
            @submit="submit('priceConsultFromRef')"
          />
          <div class="vd_export" v-if="isShow">
            <el-button type="primary" size="small" @click="buttonRefresh">刷新</el-button>
            <el-button size="small" type="success" v-if="priceConsultFrom.table_top_status === 0" @click="distribute"> 一键下发 </el-button>
            <el-button
              size="small"
              type="primary"
              v-if="priceConsultFrom.dept_name === '采购二部' && priceConsultFrom.table_top_status !== 0"
              @click="subToBoss"
            >
              提交
            </el-button>
          </div>
          <div class="vd_export2 vg_mr_8">
            <span style="margin-right: 2px">询价单编号:</span>
            <el-input size="mini" style="width: 150px" disabled v-model="priceConsultFrom.price_consult_no" show-word-limit></el-input>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form :model="priceConsultFrom" size="mini" ref="priceConsultFromRef" :rules="priceConsultRules" label-width="120px" :disabled="isShow">
      <el-row class="vg_mt_8">
        <el-col :md="5">
          <el-form-item label="客户简称:" prop="cust_abbr">
            <el-input v-model="priceConsultFrom.cust_abbr" placeholder="暂无客户简称" disabled show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item label="系列名称:" prop="series_name">
            <el-input v-model="priceConsultFrom.series_name" placeholder="暂无系列名称" disabled show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item label="销售部门:" prop="scon_dept_id">
            <el-select v-model="priceConsultFrom.scon_dept_id" placeholder="暂无销售部门" size="small" clearable disabled filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item label="核价人:" prop="pricing_user_id">
            <el-select v-model="priceConsultFrom.pricing_user_id" placeholder="请选择核价人" size="small" clearable filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <div style="text-align: center; float: right; margin-right: 30px">
          报价状态:
          <el-tag :type="getStatusName(priceConsultFrom.table_top_status).type">
            {{ getStatusName(priceConsultFrom.table_top_status).name }}
          </el-tag>
        </div>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="20">
          <el-form-item label="外销备注:" prop="inquiry_remark">
            <el-input
              v-model="priceConsultFrom.inquiry_remark"
              type="textarea"
              rows="3"
              placeholder="请输入"
              maxlength="255"
              show-word-limit
              clearable
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="priceConsultFrom.dept_name === '采购二部' && priceConsultFrom.table_top_status !== 0">
        <div v-for="item in subItemList">
          <div style="width: 70px; display: inline-block">{{ item.subStffName }}</div>
          ：
          <el-tag :type="item.subStatus === '已提交' ? 'success' : 'info'">{{ item.subStatus }}</el-tag>
        </div>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <div class="vd_button_group vg_mtb_16">
            <el-button type="success" size="small" @click="exportPartList" :disabled="false">导出excel</el-button>
          </div>
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="部件信息" name="first" :key="'first'">
              <PriceConsultPartList
                ref="PriceConsultPartList"
                :priceConsultFrom="priceConsultFrom"
                :suppList="suppList"
                :destroyFlag="destroyFlag"
                :logUser="logUser"
                :deptList="deptList"
                :stffList="stffList"
                @updateDestroyFlag="updateDestroyFlag"
              ></PriceConsultPartList>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="vg_mt_16 vd_bortop" v-if="isItAProcurementDepartment">
      <el-col>
        <div style="padding-top: 10px">DYJ核价明细</div>
      </el-col>
      <el-col>
        <div style="padding-top: 10px">
          <span style="display: inline-block">我司货号：</span>
          <el-select
            @change="getDyjData"
            v-model="prod_no"
            :disabled="isShow"
            style="display: inline-block; width: 200px"
            size="small"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option v-for="item in prodNoList" :key="item.quta_id" :label="item.prod_no" :value="item.quta_id"></el-option>
          </el-select>
        </div>
      </el-col>
      <el-col>
        <el-tabs v-model="activeName2" class="vd_eltab vd_buttom">
          <el-tab-pane label="原材料" name="first" :key="'first'">
            <DynamicUTable
              ref="otherTable1"
              :tableData="otherTableData1"
              :columns="otherTableProperties1"
              :need-pagination="false"
              :need-check-box="false"
              :is-show="isShow"
            />
            <div style="float: right">
              <span> 合计:{{ truncateDecimal(objectArrayReduce(otherTableData1, 'quta_money')) }} </span>
              <br />
              <span> 税价合计:{{ truncateDecimal(objectArrayReduce(otherTableData1, 'quta_money') * 1.05) }} </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="辅助材料" name="second" :key="'second'">
            <DynamicUTable
              ref="otherTable2"
              :tableData="otherTableData2"
              :columns="otherTableProperties2"
              :need-pagination="false"
              :need-check-box="false"
              :is-show="isShow"
            />
            <div style="float: right">
              <span> 合计:{{ truncateDecimal(objectArrayReduce(otherTableData2, 'quta_money')) }} </span>
              <br />
              <span> 税价合计:{{ truncateDecimal(objectArrayReduce(otherTableData2, 'quta_money') * 1.05) }} </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="人工工资" name="third" :key="'third'">
            <DynamicUTable
              ref="otherTable3"
              :tableData="otherTableData3"
              :columns="otherTableProperties3"
              :need-pagination="false"
              :need-check-box="false"
              :is-show="isShow"
            />
            <div style="float: right">
              <span> 合计:{{ truncateDecimal(objectArrayReduce(otherTableData3, 'money')) }} </span>
              <br />
              <span> 税价合计:{{ truncateDecimal(objectArrayReduce(otherTableData3, 'money') * 1.13) }} </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="配件信息" name="fourth" :key="'fourth'">
            <DynamicUTable
              ref="otherTable1"
              :tableData="otherTableData4"
              :columns="otherTableProperties4"
              :need-pagination="false"
              :need-check-box="false"
              :is-show="isShow"
            />
            <div style="float: right">
              <span> 合计:{{ truncateDecimal(objectArrayReduce(otherTableData4, 'quta_part_price')) }} </span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="选择下发人" :visible.sync="popUpShow" width="400px">
      <el-checkbox-group v-model="checkStffIds">
        <el-checkbox v-for="item in stffList" :label="item.stff_id" :key="item.stff_id">{{ item.stff_name }}</el-checkbox>
      </el-checkbox-group>
      <div class="vg_button" style="text-align: right">
        <el-button size="small" type="primary" @click="submitPa(checkStffIds)">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import PriceConsultPartList from '@/views/SalesManagement/PriceConsultManage/TabChild/PriceConsultPartList.vue';
import { get, post } from '@api/request';
import { priceConsultAPI } from '@api/modules/priceConsult';
import { requiredReminder } from '@assets/js/domUtils';
import { stffAPI } from '@api/modules/staff';
import { suppAPI } from '@api/modules/supp';
import { inquiryAPI } from '@api/modules/inquiry';
import { otherTableProperties1, otherTableProperties2, otherTableProperties3, otherTableProperties4 } from '@/views/SalesManagement/InquiryManage/inquiry';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { downloadFile, getDept } from '@api/public';
import { deptAPI } from '@api/modules/department';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'PriceConsultEditMain',
  components: {
    DynamicUTable,
    PriceConsultPartList,
    editHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      loadingFlag: false,
      btn: {},
      stffForm: {},
      activeName: 'first',
      activeName2: 'first',
      loadFlag: true,
      isItAProcurementDepartment: false,
      prod_no: null,
      otherTableData1: [],
      otherTableData2: [],
      otherTableData3: [],
      otherTableData4: [],
      subItemList: [],
      otherTableProperties1: otherTableProperties1,
      otherTableProperties2: otherTableProperties2,
      otherTableProperties3: otherTableProperties3,
      otherTableProperties4: otherTableProperties4,
      priceConsultFrom: {
        price_consult_no: null, //询价单编号
        cust_id: null, //客户id
        series_name: null, //系列名称
        scon_dept_id: null, //销售部门
        pricing_user_id: null, //核价人id
        table_top_status: null, //台面报价状态0:未报价 1:报价中 2:已报价
        inquiry_remark: null, //备注
        status: null, //状态
        stff_name: null, //核价人姓名
        cust_abbr: null, //客户简称
        dept_name: null, //销售部门名称
        priceConsultPartList: [], //子表list
        destroy_flag: null //删除标识字段
      },
      priceConsultRules: {
        pricing_user_id: [{ required: true, message: '请选择核价人', trigger: 'blur' }],
        scon_dept_id: [{ required: true, message: '', trigger: 'blur' }]
      },
      stffList: [], //人员list
      suppList: [], //供应商list
      prodNoList: [], //我司货号list
      destroyFlag: [], //删除的部件
      logUser: {},
      deptList: [], //部门list
      popUpShow: false,
      checkStffIds: [],
      checkList: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    objectArrayReduce,
    async initData() {
      this.getSupp();
      this.getDeptList();
      let currentPersonDept = this.$cookies.get('userInfo').dept_id;
      let caigou1 = await getDept('采购一部', 'ids');
      this.isItAProcurementDepartment = caigou1.includes(currentPersonDept) || currentPersonDept === 1;
    },
    truncateDecimal(num) {
      return Math.floor(num * 10000) / 10000;
    },
    getDeptList() {
      get(deptAPI.getAllDeptsV1).then(res => {
        if (res.data.code === 0) {
          this.deptList = res.data.data;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    },
    //询价工厂/价格
    handleSubTableData() {
      this.priceConsultFrom.priceConsultPartList.forEach(item => {
        this.$set(item, 'tempPrice', item.inquiryFactoryPrice.indexOf(',') !== -1 ? item.inquiryFactoryPrice.split(',') : [item.inquiryFactoryPrice]);
        this.$set(item, 'tempTime', item.inquiryFactoryTime.indexOf(',') !== -1 ? item.inquiryFactoryTime.split(',') : [item.inquiryFactoryTime]);
        let temp = [];
        if (item.inquiryFactory.indexOf(',') !== -1) {
          let split = item.inquiryFactory.split(',');
          split.forEach(subItem => {
            if (!subItem) {
              temp.push({ value: null, label: null });
            } else {
              let { supp_id, supp_abbr } = this.suppList.find(({ supp_id }) => supp_id === Number(subItem)) || {};
              temp.push({ value: supp_id, label: supp_abbr });
            }
          });
        } else if (item.inquiryFactory) {
          let { supp_id, supp_abbr } = this.suppList.find(({ supp_id }) => supp_id === Number(item.inquiryFactory)) || {};
          temp.push({ value: supp_id, label: supp_abbr });
        }
        this.$set(item, 'tempSupp', temp);
        this.$set(item, 'tempSize', item['tempSupp'].length);
      });
    },
    //导出excel
    exportPartList() {
      priceConsultAPI.exportPartList({ price_consult_id: this.priceConsultFrom.price_consult_id }).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName });
      });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate((valid, object) => (valid ? this.saveInfo() : requiredReminder(object)));
        })
        .catch(e => {
          console.log(e);
          this.$message.info('已取消保存');
        });
    },
    //保存
    saveInfo() {
      //非未报价状态下判断必填
      if (this.priceConsultFrom.table_top_status !== 0) {
        let flag = 0;
        this.priceConsultFrom.priceConsultPartList.forEach(item => {
          //工厂必须都填
          let isSupp = item.tempSupp.find(item2 => !item2);
          if (isSupp) {
            flag++;
          }
          //价格至少填一个
          let isPrice = item.tempPrice.find(item2 => item2);
          if (!isPrice) {
            flag++;
          }
        });
        if (flag) {
          return this.$message.warning('询价工厂/价格必填！');
        }
      }
      this.priceConsultFrom.priceConsultPartList.forEach(item => {
        //格式化工厂/价格/时间
        let temp1Price = item.tempPrice;
        let temp2Supp = Array.from(item.tempSupp, ({ value }) => value);
        let temp3Time = item.tempTime;
        let tempSup = [];
        let tempPri = [];
        let tempTim = [];
        temp2Supp.forEach((item, index) => {
          if (item) {
            tempSup.push(item);
            tempPri.push(temp1Price[index] ? temp1Price[index] : '');
            tempTim.push(temp3Time[index] ? temp3Time[index] : '');
          }
        });
        item['inquiryFactory'] = tempSup.join(',');
        item['inquiryFactoryPrice'] = tempPri.join(',');
        item['inquiryFactoryTime'] = tempTim.join(',');
        item.duty_stff_ids = item.duty_stff_ids.join(',');
        item.tempSupp = null;
        item.tempPrice = null;
        item.tempTime = null;
        item.tempSize = null;
        item.del_FactoryIds = item.del_FactoryIds
          .split(',')
          .filter(value => !item.inquiryFactory.split(',').includes(value))
          .join(',');
      });
      this.priceConsultFrom.priceConsultPartList = this.priceConsultFrom.priceConsultPartList.concat(this.destroyFlag);
      post(priceConsultAPI.editPriceConsult, this.priceConsultFrom).then(res => {
        if (res.data.code === 0) {
          this.$message.success('保存成功!');
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    },
    //获取数据
    getById() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(priceConsultAPI.getPriceConsultById, { price_consult_id: props.form_id }).then(res => {
        if (res.data.code === 0) {
          this.priceConsultFrom = res.data.data.priceConsultById;
          //处理判空start
          if (this.priceConsultFrom.pricing_user_id === 0) {
            this.priceConsultFrom.pricing_user_id = null;
          }
          this.priceConsultFrom.priceConsultPartList.forEach((item, index) => {
            if (item.prod_suffix === 0) {
              item.prod_suffix = null;
            }
            if (item.scon_stff_id === 0) {
              item.scon_stff_id = null;
            }
            if (item.total_price === 0) {
              item.total_price = null;
            }
            if (item.stff_id === 0) {
              item.stff_id = null;
            }
            if (item.table_top_price === 0) {
              item.table_top_price = null;
            }
            if (item.pricing_quotation === 0) {
              item.pricing_quotation = null;
            }
            if (item.table_top_delivery_time === 0) {
              item.table_top_delivery_time = null;
            }
            if (item.duty_stff_ids) {
              item.duty_stff_ids = item.duty_stff_ids.split(',').map(Number);
            } else {
              item.duty_stff_ids = [];
            }
            //key值重置
            item.key = index;
          });
          this.handleSubTableData();
          this.prodNoList = res.data.data.prodNoList;
          //end
          this.stffForm.dept_name = this.priceConsultFrom.dept_name;
          this.stffForm.dept_team_name = this.priceConsultFrom.dept_team_name;
          this.stffForm.stff_name = this.priceConsultFrom.stff_name;
          this.stffForm.user_id = this.priceConsultFrom.user_id;
          this.stffForm.dept_id = this.priceConsultFrom.dept_id;
          this.stffForm.stff_id = this.priceConsultFrom.stff_id;
          this.logUser = this.$cookies.get('userInfo');
          this.btn = res.data.data.btn;
          this.loadFlag = true;
          if (this.priceConsultFrom.subItem) {
            this.subItemList = JSON.parse(this.priceConsultFrom.subItem);
          }
          //获取人员
          this.getStff();
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    },
    //获取咨询清单报价四张子表数据
    getDyjData(quta_id) {
      //获取四张子表数据
      inquiryAPI
        .getQuTaByInquiry({
          inquiry_id: this.priceConsultFrom.inquiry_id,
          quta_id: quta_id
        })
        .then(({ data }) => {
          this.otherTableData1 = data.rqtaMtrlList; //原材料
          this.otherTableData2 = data.rqtaAuxmtrlList; //辅助材料
          this.otherTableData3 = data.inquWagesList; //人工工资
          this.otherTableData4 = data.rqtaPartList; //配件信息
        });
    },
    //子组件传值更改删除list
    updateDestroyFlag(val) {
      this.destroyFlag = this.destroyFlag.concat(val);
    },
    // 转换合同状态
    getStatusName(status) {
      if (status === 0) {
        return { name: '未报价', type: 'info' };
      } else if (status === 1) {
        return { name: '报价中', type: 'warning' };
      } else {
        return { name: '已报价', type: 'success' };
      }
    },
    //刷新
    buttonRefresh() {
      this.initData();
    },
    //获取人员
    getStff() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            let newStffList = [];
            if (this.priceConsultFrom.dept_name === '采购一部') {
              for (let i = 0; i < this.stffList.length; i++) {
                //采购一部
                if (this.stffList[i].dept_id === 6) {
                  newStffList.push(this.stffList[i]);
                }
              }
            } else if (this.priceConsultFrom.dept_name === '采购二部') {
              for (let i = 0; i < this.stffList.length; i++) {
                //采购二部
                if (this.stffList[i].dept_id === 7) {
                  newStffList.push(this.stffList[i]);
                }
              }
            }
            this.stffList = newStffList;
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            this.getById();
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //一键下发
    distribute() {
      if (this.priceConsultFrom.dept_name === '采购二部') {
        this.popUpShow = true;
      } else {
        get(priceConsultAPI.distribute, { price_consult_id: this.priceConsultFrom.price_consult_id })
          .then(res => {
            if (res.data.code === 0) {
              this.$message.success('下发成功！');
              this.buttonRefresh();
            }
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    // 确认下发
    submitPa(checkStffIds) {
      if (checkStffIds.length === 0) {
        this.$message({ type: 'warning', message: '请至少勾选一个下发人员！' });
      } else {
        get(priceConsultAPI.distribute, {
          price_consult_id: this.priceConsultFrom.price_consult_id,
          stffIds: checkStffIds.join(',')
        })
          .then(res => {
            if (res.data.code === 0) {
              this.$message.success('下发成功！');
              this.popUpShow = false;
              this.buttonRefresh();
            }
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    //提交
    subToBoss() {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          get(priceConsultAPI.subToBoss, {
            price_consult_id: this.priceConsultFrom.price_consult_id,
            user_id: this.logUser.user_id
          })
            .then(res => {
              if (res.data.code === 0) {
                this.$message.success('提交成功！');
                this.buttonRefresh();
              }
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.el-checkbox-group ::v-deep.el-checkbox {
  width: 90px;
}
</style>
