<template>
  <div class="priceConsultPartList">
    <!--    <div class="vg_mb_16">-->
    <!--      <el-button type="primary" size="small" @click="copyPart()">复制</el-button>-->
    <!--      <el-button size="small" type="danger" @click="deletePart()">删除</el-button>-->
    <!--    </div>-->
    <el-table
      ref="priceConsultPartTable"
      :header-cell-style="{ 'text-align': 'center' }"
      tooltip-effect="dark"
      border
      :data="priceConsultFrom.priceConsultPartList"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column width="120" label="产品图片" prop="imge_url">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.imge_url'">
            <el-image
              v-if="priceConsultFrom.priceConsultPartList[scope.$index].imge_url.indexOf('aliyun') !== -1"
              :src="priceConsultFrom.priceConsultPartList[scope.$index].imge_url"
              :previewSrcList="[priceConsultFrom.priceConsultPartList[scope.$index].imge_url]"
              fit="contain"
              placeholder="暂无产品图片"
            >
            </el-image>
            <span v-else>暂无</span>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="我司货号" prop="prod_no" sortable>
        <template slot="header">
          <span class="vg_deep_red">我司货号</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" sort :prop="'priceConsultPartList.' + scope.$index + '.prod_no'" :rules="{ required: true, message: ' ' }">
            <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].prod_no" show-word-limit disabled placeholder="暂无我司货号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="更改号" prop="prod_suffix">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.prod_suffix'">
            <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].prod_suffix" show-word-limit disabled placeholder="暂无更改号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="客户货号" prop="prod_cust_no">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.prod_cust_no'">
            <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].prod_cust_no" show-word-limit disabled placeholder="暂无客户货号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="询价人" prop="scon_stff_name">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.scon_stff_id'">
            <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].scon_stff_name" show-word-limit disabled placeholder="暂无询价人"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="中文名称" prop="prod_name">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.prod_name'">
            <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].prod_name" show-word-limit disabled placeholder="暂无中文名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="部件名称" prop="prod_partno">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.prod_partno'">
            <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].prod_partno" show-word-limit disabled placeholder="暂无部件名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="包装/送货要求" prop="pack_ement">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.pack_ement'">
            <el-tooltip class="item" effect="light" :content="priceConsultFrom.priceConsultPartList[scope.$index].pack_ement" placement="top-start">
              <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].pack_ement" show-word-limit disabled placeholder="暂无包装要求"></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="预计数量" prop="estimat_number">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.estimat_number'">
            <el-input
              v-model="priceConsultFrom.priceConsultPartList[scope.$index].estimat_number"
              show-word-limit
              disabled
              placeholder="暂无预计数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="130" label="预计交期" prop="delivery_time">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.delivery_time'">
            <el-date-picker
              disabled
              v-model="priceConsultFrom.priceConsultPartList[scope.$index].delivery_time"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="目标价" prop="target_price">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.target_price'">
            <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].target_price" show-word-limit disabled placeholder="暂无目标价"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="外销备注" prop="remarks">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.remarks'">
            <el-tooltip class="item" effect="light" :content="priceConsultFrom.priceConsultPartList[scope.$index].remarks" placement="top-start">
              <el-input v-model="priceConsultFrom.priceConsultPartList[scope.$index].remarks" show-word-limit disabled placeholder="暂无外销备注"></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="采购备注" prop="pord_remark">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.pord_remark'">
            <el-tooltip class="item" effect="light" :content="priceConsultFrom.priceConsultPartList[scope.$index].pord_remark" placement="top-start">
              <el-input
                type="textarea"
                v-model="priceConsultFrom.priceConsultPartList[scope.$index].pord_remark"
                show-word-limit
                placeholder="暂无采购备注"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column width="140" label="负责台面" prop="duty_stff_ids" v-if="priceConsultFrom.dept_name === '采购二部'">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-select size="small" v-model="priceConsultFrom.priceConsultPartList[scope.$index].duty_stff_ids" multiple filterable placeholder="请选择">-->
      <!--            <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>-->
      <!--          </el-select>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column width="450" label="询价工厂 / 价格 / 沟通交期" prop="inquiryFactory">
        <template slot="header">
          <span class="vg_deep_red">询价工厂 / 价格 / 沟通交期</span>
        </template>
        <template v-slot="scope">
          <el-col :span="20" v-for="(item, index) in priceConsultFrom.priceConsultPartList[scope.$index]['tempSize']" :key="`tempSize${index}`">
            <div style="display: flex">
              <el-select
                class="vg_mr_8"
                size="mini"
                filterable
                v-model="priceConsultFrom.priceConsultPartList[scope.$index]['tempSupp'][index]"
                placeholder="请选择工厂"
                clearable
              >
                <el-option
                  v-for="item in suppList"
                  :key="`tempSize${item.supp_id}${index}`"
                  :label="item.supp_abbr + '(' + item.supp_no + ')'"
                  :value="{ value: item.supp_id, label: item.supp_abbr }"
                />
              </el-select>
              <el-tooltip class="item" effect="light" :content="priceConsultFrom.priceConsultPartList[scope.$index]['tempPrice'][index]" placement="top-start">
                <el-input
                  size="mini"
                  style="width: 320px"
                  :maxlength="priceConsultFrom.dept_name === '采购一部' ? 9 : 200"
                  show-word-limit
                  @input="
                    val =>
                      (priceConsultFrom.priceConsultPartList[scope.$index]['tempPrice'][index] =
                        priceConsultFrom.dept_name === '采购一部' ? keep4Decimal(val) : val)
                  "
                  v-model="priceConsultFrom.priceConsultPartList[scope.$index]['tempPrice'][index]"
                  clearable
                  placeholder="请填写价格"
                />
              </el-tooltip>
              <el-date-picker
                size="mini"
                value-format="timestamp"
                v-model="priceConsultFrom.priceConsultPartList[scope.$index]['tempTime'][index]"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
          </el-col>
          <el-button
            icon="el-icon-plus"
            circle
            size="mini"
            @click="
              () => {
                priceConsultFrom.priceConsultPartList[scope.$index]['tempSize'] = priceConsultFrom.priceConsultPartList[scope.$index]['tempSize'] + 1;
                priceConsultFrom.priceConsultPartList[scope.$index]['tempSupp'].push({ value: null, label: null });
              }
            "
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column width="120" label="采购确认工厂" prop="supp_id">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.supp_id'">
            <el-select
              v-model="priceConsultFrom.priceConsultPartList[scope.$index].supp_id"
              placeholder="暂无采购确认工厂"
              size="small"
              clearable
              filterable
              @change="
                val => {
                  editSuppAbbrAndpurcStffId(val, scope.$index);
                }
              "
            >
              <el-option
                v-for="item in priceConsultFrom.priceConsultPartList[scope.$index]['tempSupp']"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="采购确认价" prop="table_top_price">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.table_top_price'">
            <el-input
              v-model="priceConsultFrom.priceConsultPartList[scope.$index].table_top_price"
              show-word-limit
              maxlength="9"
              clearable
              @input="val => (priceConsultFrom.priceConsultPartList[scope.$index].table_top_price = keep4Decimal(val))"
              placeholder="暂无台面报价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="130" label="确认交期" prop="table_top_delivery_time">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'priceConsultPartList.' + scope.$index + '.table_top_delivery_time'">
            <el-date-picker
              value-format="timestamp"
              v-model="priceConsultFrom.priceConsultPartList[scope.$index].table_top_delivery_time"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import { getDate } from '@assets/js/dateUtils';
import helper from '@assets/js/helper';
import { BigNumber } from 'bignumber.js';
import { keep4Decimal, keepNumber } from '@assets/js/regExUtil';

export default {
  name: 'PriceConsultPartList',
  computed: {
    BigNumber() {
      return BigNumber;
    },
    helper() {
      return helper;
    }
  },
  props: {
    priceConsultFrom: {
      type: Object
    },
    suppList: {
      type: Array
    },
    destroyFlag: {
      type: Array
    },
    logUser: {
      type: Object
    },
    deptList: {
      type: Array
    },
    stffList: {
      type: Array
    }
  },
  data() {
    return {
      selectionsList: []
    };
  },
  created() {},
  mounted() {},
  methods: {
    keep4Decimal,
    keepNumber,
    getDate, //获取人员
    tableRowClassName({ row, rowIndex }) {
      //如果子表负责台面里有登录人则变色
      if (row.duty_stff_ids.includes(this.logUser.stff_id)) {
        return 'success-row';
      }
      return '';
    },
    //复制
    copyPart() {
      if (this.selectionsList.length === 0) {
        this.$message({
          type: 'error',
          message: '至少选择一条数据！'
        });
        return;
      }
      if (this.selectionsList.length > 1) {
        this.$message({
          type: 'error',
          message: '只能选择一条数据！'
        });
        return;
      }
      this.$prompt('请确认复制数量，上限为20', '复制', {
        confirmButtonText: '确定复制',
        cancelButtonText: '取消',
        inputPattern: /^[\d.]+$/,
        inputErrorMessage: '请输入正确的数量',
        inputValue: 5
      }).then(({ value }) => {
        if (value > 20) {
          this.$message({
            type: 'error',
            message: '单次只能复制20份！'
          });
          return;
        }
        for (let i = 0; i < value; i++) {
          let priceConsultClone = cloneDeep(this.selectionsList);
          priceConsultClone.forEach(item => {
            item.price_consult_part_id = 0;
            item.onlyId = 0;
          });
          this.priceConsultFrom.priceConsultPartList = this.priceConsultFrom.priceConsultPartList.concat(priceConsultClone);
        }
        //key值重置
        this.reductionKey();
      });
    },
    //删除
    deletePart() {
      if (this.selectionsList.length === 0) {
        this.$message({
          type: 'error',
          message: '至少选择一条数据！'
        });
        return;
      }
      this.$confirm('确定删除？但会保留原始数据！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // if (this.priceConsultFrom.priceConsultPartList.length === this.selectionsList.length) {
          //   return this.$message.error('不能删除全部数据！');
          // }
          // if (this.selectionsList.length > 1) {
          //   return this.$message.error('不可多选删除！');
          // }
          // let isOk = false;
          // this.selectionsList.forEach(item => {
          //   let count = this.priceConsultFrom.priceConsultPartList.filter(item1 => item1.onlyId === item.onlyId).length;
          //   if (count <= 1) {
          //     isOk = true;
          //     return false;
          //   }
          // });
          // if (isOk) {
          //   return this.$message.error('不能删除唯一数据！');
          // }
          //不删除唯一数据start
          let only = [];
          for (let i = 0; i < this.selectionsList.length; i++) {
            for (let j = 0; j < this.priceConsultFrom.priceConsultPartList.length; j++) {
              if (this.selectionsList[i].onlyId && this.selectionsList[i].onlyId === this.priceConsultFrom.priceConsultPartList[j].onlyId) {
                only.push(j);
              }
            }
          }
          only = only.sort((a, b) => {
            return b - a;
          });
          for (let i of only) {
            this.selectionsList.splice(i, 1);
          }
          //end
          //删除start
          let destroyList = [];
          this.selectionsList.forEach(item => {
            if (item.price_consult_part_id) {
              item.destroy_flag = 1;
              destroyList.push(cloneDeep(item));
            }
          });
          this.$emit('updateDestroyFlag', destroyList);
          // this.priceConsultFrom.priceConsultPartList = this.priceConsultFrom.priceConsultPartList.filter(item =>
          //   this.selectionsList.every(item1 => item.price_consult_part_id !== item1.price_consult_part_id)
          // );
          let temp = [];
          for (let i = 0; i < this.selectionsList.length; i++) {
            for (let j = 0; j < this.priceConsultFrom.priceConsultPartList.length; j++) {
              if (this.selectionsList[i].key === this.priceConsultFrom.priceConsultPartList[j].key) {
                temp.push(j);
              }
            }
          }
          temp = temp.sort((a, b) => {
            return b - a;
          });
          for (let i of temp) {
            this.priceConsultFrom.priceConsultPartList.splice(i, 1);
          }
          //end
          //重置key值
          this.reductionKey();
          //关闭多选框
          this.$refs.priceConsultPartTable.clearSelection();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.priceConsultPartTable.clearSelection();
        });
    },
    // key值重置
    reductionKey() {
      for (let i = 0; i < this.priceConsultFrom.priceConsultPartList.length; i++) {
        this.priceConsultFrom.priceConsultPartList[i].key = Number(i);
      }
    },
    editSuppAbbrAndpurcStffId(val, index) {
      if (this.priceConsultFrom.dept_name === '采购一部') {
        //联动采购确认价格
        this.priceConsultFrom.priceConsultPartList[index]['tempSupp'].forEach((item, index2) => {
          if (val === item.value) {
            this.priceConsultFrom.priceConsultPartList[index].table_top_price = this.priceConsultFrom.priceConsultPartList[index]['tempPrice'][index2];
            this.priceConsultFrom.priceConsultPartList[index].table_top_delivery_time = this.priceConsultFrom.priceConsultPartList[index]['tempTime'][index2];
          }
        });
      }
    },
    //供应商，工厂，对应台面联动删除方法
    clearAbbrSttf(index) {
      this.priceConsultFrom.priceConsultPartList[index].supp_no = null;
      this.priceConsultFrom.priceConsultPartList[index].supp_abbr = null;
      this.priceConsultFrom.priceConsultPartList[index].stff_id = null;
    },
    //选择
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //采购二部只可更改自己的数据
    checkDeptAndStff(val) {
      if (this.logUser.dept_id === 7) {
        return this.logUser.stff_id !== val;
      }
    },
    //只有经理和核价人可以更改供应商和工厂
    checkDeptAndStffByDept2(val) {
      // return !(this.logUser.stff_id === this.department.stff_id || this.logUser.stff_id === val);
    },
    //对应台面非登录人则不可以更改台面报价
    checkDeptAndStff2(val) {
      if (this.logUser.stff_id !== val) {
        return true;
      }
    },
    //登录人为核价人则可以更改全部的核价报价
    checkDeptAndStff3(val) {
      return this.logUser.stff_id !== val;
    }
  }
};
</script>
<style>
.el-table .success-row {
  background: #f0fff0;
}
</style>
